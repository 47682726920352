
var imgBase = "https://img2.cncenter.cz"; // Zakladni domena pro obrazky a dalsi obsah.
var petrek_dev = imgBase + "/static/weather/cz/2/radar/rad";   // tady dat cestu jak to bude na livu pro -radary
var hovorka_dev = imgBase + "/static/weather/cz/2/forecast/cnc_";  // tady dat cestu jak to bude na livu -zbytek

var version='5'; // coz change test pic on webdev...not necesary on live
var now= new Date();
var hours = now.getHours()+""; if(hours.length==1)  hours="0" + hours;
var mins=now.getMinutes()+""; if(mins.length==1)  mins="0" + mins;
var actu_aladin_img='12';
var class_name='rad';
var chart_type='';
var generated_time='02';

var twoDigitMonth = now.getMonth()+1;
    twoDigitMonth=twoDigitMonth.toString();

    if(twoDigitMonth.length==1)
        { twoDigitMonth="0" + twoDigitMonth;}

var twoDigitDate = now.getDate()+"";
    twoDigitDate=twoDigitDate.toString();

    if(twoDigitDate.length==1)
        { twoDigitDate="0" +twoDigitDate;}

var today_date = now.getFullYear() + "" + twoDigitMonth + "" + twoDigitDate;    // napr 20140205
var today_date_fix = today_date;
var yesterday_date = Number(today_date)-1; yesterday_date=yesterday_date.toString();
var timestamp = now.getTime();

/* radar, aladin, cloud and temp tab change */
var tabs_switch=function(class_name){
               
         $(".radar .radar-control").removeClass('active');
         $(".radar ."+class_name).addClass("active");


         if (class_name == 'rad') {
             $(".leaflet-overlay-pane img").hide();
             $(".leaflet-overlay-pane img").eq(0).show();
             
            
                $(".stop-aladin").hide();
                $(".start-aladin").hide();
                $(".start").show();
                $(".radar-h1").text("Radar");
                 
         }

         if (class_name == 'ala') {

                   $(".leaflet-overlay-pane img").hide();
                   $(".leaflet-overlay-pane img").eq(1).show();
                  
                $(".start-aladin").show();
                $(".stop-aladin").hide();
                $(".start").hide();
                $(".stop").hide();
                chart_type='pre'; 
                 $(".radar-h1").text("Srážky"); 

         }

         if (class_name == 'clo') {
             $(".leaflet-overlay-pane img").hide();
             $(".leaflet-overlay-pane img").eq(2).show();
            
                $(".start-aladin").show();
                $(".stop-aladin").hide();
                $(".start").hide();
                $(".stop").hide();
                  chart_type='cld';   
                  $(".radar-h1").text("Oblačnost"); 
         }

         if (class_name == 'tem') {
             $(".leaflet-overlay-pane img").hide();
             $(".leaflet-overlay-pane img").eq(3).show();
             
                $(".start-aladin").show();
                $(".stop-aladin").hide();
                $(".start").hide();
                $(".stop").hide();
                  chart_type='tmp'; 
                  $(".radar-h1").text("Teplota");   
         }
 }

/* creating bottom panels for control aladin, clouds and temp*/
/* spousta var. je zbytecna..menil se system nacitani obrazku..tuhle sekci procistit */
/* je treba udelat i prechod na letni cas vsude kde jsou  xxx zmenit na x na x+1 */
var aladin_create_control = function () {
    var aladin_act_date = aladinDate;
    var hours = now.getHours() + "";
    if (hours.length === 1) hours = "0" + hours;
    var forecast = '06';

    actu_aladin_img = forecast;
    var vypis = generated_time;
    var plus6 = Number(vypis);

    actu_aladin_img_fix = actu_aladin_img;

    for (var i = 1; i < 5; i++) {
        if (plus6 > 22) {
            plus6 = '2';
        }

        $(".tlacitka-aladin").append("<span class='" + forecast + " time-point-aladin' id='" + aladin_act_date + "' title='" + plus6 + "'  > </span>");
        $("." + forecast).append("<span class='ar'> </span>");

        $("." + forecast).append("<span class='p-time'>" + plus6 + ':00' + "</span>");
        $(".tlacitka-aladin").after("<span class='time-print-aladin'>" + plus6 + ":00</span>");
        forecast = Number(forecast) + 6;
        if (forecast > 24) {
            forecast = '00';
        }

        plus6 = Number(vypis) + 6;

        vypis = Number(vypis) + 6;
        if (vypis > 20) {
            vypis = '2';   /* xxx */
        }

    }
};

/*  radar control panel with timepoints for big map */
var radar_create_control_big = function(draw,now_time) {

    for (var i = 1; i < 21; i++) {
         
        var skip=draw.substr(2,2);
                        
        /*  Vynechani  60, 70, 80 , 90, po 0650 nebude 0660 ale 0700 */  
        if ( skip == '60') {
                            
            draw=draw.substr(0,2)+'00';
                                
            draw=Number(draw)+100;
            draw=draw.toString();
                            
                if (draw.length==3) {
                    draw ='0'+draw;
                }
                            
        }

        $(".tlacitka").append("<span class='"+draw+" time-point' id='"+draw+"' title='"+draw.substr(0,2)+":"+draw.substr(2,2)+"'> </span>");
        $("."+draw).append("<span class='ar'> </span>");
        $("."+draw).append("<span class='p-time'>"+draw.substr(0,2)+":"+draw.substr(2,2)+"</span>");
        
        if(i==1 || i==4 || i==5 || i==8 ||  i==9 ||  i==12 || i==13 || i==16 || i==17 || i==20) {
            $(".tlacitka").after("<span class='time-print'>"+draw.substr(0,2)+":"+draw.substr(2,2)+"</span>");
                if(i==4 || i==8 || i==12 || i==16) {
                     $("." + draw).addClass('time-space');
                }   

        } 
        if(draw==now_time){
            $("."+draw).addClass("time-point-active");
            $("."+draw).find(".p-time").show();
            $("."+draw).find(".ar").show();
        }
                    
        if(draw != '2345'){
                             
            draw = Number(draw) +15;
            draw = draw.toString();
       
            if(draw.length==3){
                draw='0'+draw;
            }
            if(draw.length==2){
                draw='00'+draw;
            }
                                
        }
        else {
            draw='0000';
                         
        }
            
    }
}

/*  radar control panel with timepoints for small map */
var radar_create_control_small = function(draw,now_time) {

    for (var i = 1; i < 13; i++) {

        var skip=draw.substr(2,2);
                        
        /*  Vynechani  60, 70, 80 , 90, po 0650 nebude 0660 ale 0700 */  
        if ( skip == '60') {
                            
            draw=draw.substr(0,2)+'00';
            draw=Number(draw)+100;
            draw=draw.toString();
            
            if (draw.length==3) {
                draw ='0'+draw;
            }
        }

    $(".tlacitka").append("<span class='"+draw+" time-point' id='"+draw+"' title='"+draw.substr(0,2)+":"+draw.substr(2,2)+"'> </span>");
    $("."+draw).append("<span class='ar'> </span>");
    $("."+draw).append("<span class='p-time'>"+draw.substr(0,2)+":"+draw.substr(2,2)+"</span>");
    if(i==1 || i==4 || i==5 || i==8 ||  i==9 ||  i==12 ) {
            $(".tlacitka").after("<span class='time-print'>"+draw.substr(0,2)+":"+draw.substr(2,2)+"</span>");
                if(i==4 || i==8 ) {
                    $("." + draw).addClass('time-space');
                }   

    } 
    if(draw==now_time){
        $("."+draw).addClass("time-point-active");
        $("."+draw).find(".p-time").show();
        $("."+draw).find(".ar").show();
    }
                    
    if(draw != '2345'){
        draw = Number(draw) +15;
        draw = draw.toString();
       
        if(draw.length==3){
            draw='0'+draw;
        }
        if(draw.length==2){
            draw='00'+draw;
        }
                                
    }
    else {
        draw='0000';
    }
            
    }  // for cycle end
}




$(document).ready(function() {
    
    var mapHP = 'map-small';
    var mapDetail = 'map-big';   
    var lng =$(".radar-data .lng").text();
    var lat=$(".radar-data .lat").text();


    if ($(".hp #"+mapHP).length) {

        var map = L.map(mapHP, {
                    center: [50.03, 15.46],
                    zoom: 6,
                    minZoom: 5,
                    maxZoom: 12, 
                    zoomControl: false,
                    scrollWheelZoom: false
                })
    }
    else if($(".obec #"+mapHP).length) {
        var map = L.map(mapHP, {
                    center: [lat, lng],
                    zoom: 8,
                    minZoom: 5,
                    maxZoom: 12, 
                    zoomControl: false,
                    scrollWheelZoom: false
                })

        var myIcon = L.icon({
                iconUrl: imgBase + '/images/blesk-pocasi/marker-blesk.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [2, -42],
                shadowSize: [68, 95],
                shadowAnchor: [22, 94]
            });

        var mark = L.marker([lat, lng],{icon:myIcon}).addTo(map);
                mark.bindPopup(cityName).openPopup();
    }
    else {
        var map = L.map(mapDetail, {
                    center: [49.73, 15.46],
                    zoom: 7,
                    minZoom: 6,
                    maxZoom: 11, 
                    zoomControl: false,
                    scrollWheelZoom: false
                 })
       
    }

    

    $(".reload-button").on("click",function(){ 
        map.setView(new L.LatLng(49.73, 15.46), 7);
    });

    $('.map-accesible').on('click',function(){

        //map.setView(new L.LatLng(50.22, 15.46), 6);
        $(this).toggleClass('on').parent().toggleClass('activated').prev().toggleClass('unactivated');                                    

    });
          
    var zoomControl = L.control.zoom({ position: 'topright'});
    map.addControl(zoomControl);

    L.tileLayer('https://c.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {  

            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
            subdomains: '1234'

        })
        .addTo(map);
                   
    mins=Number(mins); 
          
        if (mins >= 45) {mins = '45';}
        else if (mins >= 30 && mins < 45 ) {mins = '30';}
        else if (mins >= 15 && mins < 30 ) {mins = '15';}
        else if (mins < 15 ) {mins = '00';}
                    
    var together=hours +""+ mins;
    var now_time=together; // napr 0420
    
    if($("#map-big").length) {
        var now_minus3hours =  Number(now_time)-400;  //pro HP minus 4 hodiny
    }
    else {
        var now_minus3hours =  Number(now_time)-200;  //pro HP minus 2 hodiny
    }
                    
    var actual_img_time = now_minus3hours;
    
                    
    if (actual_img_time >=-100 && actual_img_time < 0) {actual_img_time='2300';now_minus3hours='2300';today_date=yesterday_date;}  
    if (actual_img_time >=-200 && actual_img_time < -100) {actual_img_time='2200';now_minus3hours='2200';today_date=yesterday_date;}
    if (actual_img_time >=-300 && actual_img_time < -200) {actual_img_time='2100';now_minus3hours='2100';today_date=yesterday_date;}
    if (actual_img_time < -300) {actual_img_time='2000';now_minus3hours='2000';today_date=yesterday_date;} 
                                   
    actual_img_time=actual_img_time.toString(); 
    now_minus3hours=now_minus3hours.toString(); 

    if (actual_img_time.length ==3) {actual_img_time='0'+actual_img_time;now_minus3hours='0'+now_minus3hours;} 
    if (actual_img_time.length ==2) {actual_img_time='00'+actual_img_time;now_minus3hours='00'+now_minus3hours;}
    if (actual_img_time.length ==1) {actual_img_time='000'+actual_img_time;now_minus3hours='000'+now_minus3hours;} 
                    
                    
    var draw =  actual_img_time.substr(0,actual_img_time.length-2) + '00';

    if($("#map-big").length) {
                         
        radar_create_control_big(draw,now_time);
    }
    else {
        
        radar_create_control_small(draw,now_time);
    }
                  
    aladin_create_control();
                  
 /* add radar, aladin etc. into map by creating imageoverlay */



    var radar= petrek_dev+''+today_date+'-'+now_time+'.png?v='+timestamp;
    var radarBounds = [[48.065, 11.267], [51.345, 19.624]];
    L.imageOverlay(radar, radarBounds).addTo(map);

           
    var aladin = hovorka_dev+''+aladinDate+'_'+aladinForcast+'06pre.png'; //whatever pic now, just init, maybe later necesary change for actual if this is starting tab
    var aladinBounds = [[47.980, 11.687], [51.625, 19.500]];
    L.imageOverlay(aladin, aladinBounds).addTo(map);

    var clouds = hovorka_dev+''+aladinDate+'_'+aladinForcast+'06cld.png';   //whatever pic now, just init
    L.imageOverlay(clouds, aladinBounds).addTo(map);

    var temp = hovorka_dev+''+aladinDate+'_'+aladinForcast+'06tmp.png';   //whatever pic now, just init
    var tempBounds = [[48.000, 11.657], [51.585, 19.524]];
    L.imageOverlay(temp, tempBounds).addTo(map);

    $(".leaflet-overlay-pane img").hide();
    $(".leaflet-image-layer").eq(0).show();

    /* nonexistent img  */
    $(".leaflet-image-layer").eq(0).error(function() {
             $(this).hide();
    });
                                   
    /* radar start animation */       
    $(".start").on("click",function() {
                        
        $(".map-all").mouseleave( function(){ $(".anim-button-cover").hide(); });
        $(".map-all").mouseenter( function(){ $(".anim-button-cover").show(); });
        $(this).hide();
        $(".stop").show();
                    
        $(".radar-tabs .tabs").click(function(){
            $(".tabs").removeClass('active');
            $(this).addClass('active');
            var class_name= $(this).attr("class");
            class_name = class_name.substr(0,3);
            clearInterval(anim_radar);
   
            tabs_switch(class_name);
        });     

        $(".stop").click(function(){

            clearInterval(anim_radar);

            $(".start").show();
            $(".stop").hide();                

        });

        /*  animation itself */
                    
        var anim_radar = setInterval(function(){
            
           
            var skip=actual_img_time.substr(2,2);
                        
            /*  Skip  60, 70, 80 , 90  in timeshift, after 0650 will not be 0660 but 0700 */  
            if ( skip == '60') {
                 actual_img_time=actual_img_time.substr(0,2)+'00';
                 actual_img_time=Number(actual_img_time)+100;
                 actual_img_time=actual_img_time.toString();
                 
                if (actual_img_time.length==3) {
                    actual_img_time ='0'+actual_img_time;
                }
            }

              
            if (actual_img_time.substr(0,1)=='0') { today_date = today_date_fix;}
                $(".leaflet-image-layer").eq(0).attr("src",petrek_dev+''+today_date+'-'+actual_img_time+'.png').show();
                $(".time-point").removeClass("time-point-active");
                $("#"+actual_img_time).addClass("time-point-active");
                $(".p-time").hide();
                $(".ar").hide();
                $("#"+actual_img_time).find(".p-time").show();
                $("#"+actual_img_time).find(".ar").show();


            if(actual_img_time != '2345'){
                            
                /* achieving actual time  */
                if ( actual_img_time == now_time) {
                     actual_img_time = now_minus3hours;
                                
                     clearInterval(anim_radar);
                     $(".start").show();
                     $(".stop").hide();  
                }
                else {
                    actual_img_time = Number(actual_img_time) +15;
                    actual_img_time =actual_img_time.toString();

                           
                    if(actual_img_time.length==3){
                        actual_img_time='0'+actual_img_time;
                    }
                    if(actual_img_time.length==2){
                        actual_img_time='00'+actual_img_time;
                    }

                }

            }
            else {
                 actual_img_time='0000';
            }

                        
        }, 1000);
    }); 

/* aladin, clouds, temp maps animation start  */
    $(".start-aladin").on("click",function() { 

        $(this).hide();
        $(".stop-aladin").show(); 
        
        $(".radar-tabs .tabs").click(function(){       
            clearInterval(anim_aladin); 
                tabs_switch(class_name);       
        });    

        $(".stop-aladin").click(function(){
            clearInterval(anim_aladin);
            $(".start-aladin").show();
            $(".stop-aladin").hide();               

        });
                

        /* animation itself */

        var anim_aladin= setInterval(function(){

            actu_aladin_img=Number(actu_aladin_img)+6;
            if (actu_aladin_img > 24) {
                 actu_aladin_img=6;

            }
            actu_aladin_img=actu_aladin_img.toString(); 
            if(actu_aladin_img=='6'){
                actu_aladin_img='06';
            }
            var actu_date=$(".time-point-aladin."+actu_aladin_img).attr("id");

            $(".leaflet-image-layer:gt(0)").attr("src",hovorka_dev+''+actu_date+'_'+aladinForcast+''+actu_aladin_img+''+chart_type+'.png');


            $(".time-point-aladin").removeClass("time-point-active");
            $(".p-time").hide();
            $(".ar").hide();
            $(".time-point-aladin."+actu_aladin_img).addClass("time-point-active");
            $(".time-point-aladin."+actu_aladin_img).find(".p-time").show();
            $(".time-point-aladin."+actu_aladin_img).find(".ar").show();

            if (actu_aladin_img == actu_aladin_img_fix) {
                actu_aladin_img=actu_aladin_img_fix;
                clearInterval(anim_aladin);
                tabs_switch(class_name);
                    
            }


        }, 1000);

    });
    

    $(".radar-tabs .tabs").click(function(){
        $(".tabs").removeClass('active');
        $(this).addClass('active');
        class_name= $(this).attr("class");
        class_name = class_name.substr(0,3);
             
        tabs_switch(class_name);

        $(".time-point-aladin").removeClass("time-point-active").children().hide();
        $(".leaflet-image-layer:gt(0)").attr("src",hovorka_dev+''+aladinDate+'_'+aladinForcast+''+actu_aladin_img+''+chart_type+'.png');
        $(".time-point-aladin."+actu_aladin_img).addClass("time-point-active").children().show();

    });   
    
    
    /* click on aladin control panel */
    $(".time-point-aladin").click(function(){

        var rel_hour = $(this).attr("class").substr(0,2);
        var rel_date = $(this).attr("id");
                
        $(".leaflet-image-layer:gt(0)").attr("src",hovorka_dev+''+rel_date+'_'+aladinForcast+''+rel_hour+''+chart_type+'.png');
        $(".time-point-aladin").removeClass("time-point-active");
        $(".p-time").hide();
        $(".ar").hide();
        $(this).addClass("time-point-active");
        $(this).find(".p-time").show();
        $(this).find(".ar").show();
        actu_aladin_img=rel_hour;
        tabs_switch(class_name);
                       
    });       

    /* click on radar control panel */
    $(".time-point").click(function(){

        var rel_img = $(this).attr("id");
        if (rel_img.substr(0,1)=='0') { today_date = today_date_fix;}  
        $(".leaflet-image-layer").eq(0).attr("src", petrek_dev+''+today_date+'-'+rel_img+'.png').show();

        $(".time-point").removeClass("time-point-active");
        $(".p-time").hide();
        $(".ar").hide();
        $(this).addClass("time-point-active");
        $(this).find(".p-time").show();
        $(this).find(".ar").show();
        actual_img_time=rel_img;
        tabs_switch(class_name);

    });      

});         
 